.payment-field-row {
  padding: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.payment-field-row-amount {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.payment-field-row-fiat {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.asset-as-header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-field-name {
  text-align: right;
}
.text-field-name-txnid {
  text-align: right;
  margin-right: 40px;
}
.text-field-value {
  text-align: left;
}

.status-text {
  color: green;
}

.status-failure-text {
  color: red;
}
.text-field-txn-id {
  text-align: center;
  margin-bottom: 10px;
}
.internal-transfer-payment-details {
  .ant-skeleton.ant-skeleton-element.ant-skeleton-active.css-dev-only-do-not-override-1e3x2xa {
    display: flex !important;
    justify-content: center !important;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }
  .ant-skeleton-avatar-lg {
    width: 80px !important;
    height: 80px !important;
    line-height: 0px !important;
  }
  .ant-skeleton-button-lg {
    width: 230px !important;
    height: 60px !important;
    line-height: 0px !important;
  }
  .ant-skeleton .ant-skeleton-content {
    display: flex !important;
    justify-content: center !important;
  }
  .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
    padding: 0;
    margin-top: -35px;
  }
}
