.videoAdd {
  .video {
    width: 100% !important;
    height: 475px !important;
    margin-top: 20px;
  }

  .alignCenter {
    left: 50%;
    height: 50px !important;
    border-radius: 2px !important;
    border: 1px solid #ffffff;
    background: transparent;
    width: 250px;
    transform: translate(-50%, -50%);
    top: 50px;
  }

  .qr-icon {
    width: 16px;
    margin-top: -1px;
  }

  .qr-title {
    margin-left: 7px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
  }
}

.cancelQRButton {
  height: 50px !important;
  border-radius: 7px !important;
  border: 1px solid #7a7878;
  width: 40%;
}

.send-external-component {
  // add circle as backdrop for any image/container
  .circularBox {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    border: 3px solid rgba(242, 170, 60, 0.5);
  }

  input,
  input[placeholder] {
    text-align: center;
    color: #000;
  }

  input#inputBox {
    color: #a9a9a9;
    font-family: Rubik-Medium;
    border: none;
    font-weight: 600;
    font-size: 25px;
    text-align: left;
  }

  input#inputBox:hover,
  input#inputBox:focus {
    border: block;
  }

  Button {
    border-color: #757575 !important;
    color: #757575;
    border-radius: 3px !important;
  }
}

.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}
