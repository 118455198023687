.customTableContacts .tItem3 {
  color: #000;
}
.customTableContacts .tItem4 {
  float: left !important;
  position: absolute;
  margin-top: 38px;
  color: #a39898;
  left: 60px !important;
  font-size: 12px;
}
.customTable .tItem6 {
  border: 1px solid #e8e8e8;
  margin-top: 70px;
}
