$font-family-global: "Rubik", sans-serif;

.internal-avail-bal-value {
  color: #6c6c6b;
}

.internal-avail-bal-text {
  color: #636464;
  padding-top: 35px;
  font-size: 14px;
  font-weight: 400;
}

.internal-form-block {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.internal-form-text {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 16px;
  font-family: $font-family-global;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #2e3034;
  font-weight: 700;
}

.internalTransaction {
  .ant-input-group-addon {
    height: 60px !important;
  }

  .ant-input {
    height: 60px !important;
  }
}

.internal-form-value {
  margin-top: 6px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.internal-enter-amount-sart {
  padding: 18px;
  background-color: #636464;
  color: #2e3034;
  align-items: baseline;
}

.internal-enter-amount-input {
  background-color: #636464;
  color: #2e3034;
  align-items: baseline;
}

.shadow {
  box-shadow: 0 0 5px #dddbdb !important;
}

.button-position-bottom {
  background-color: white;
  margin-top: 40px;
  display: flex;
  align-items: baseline;
  justify-content: baseline;
}
