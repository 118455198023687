.navbar {
  background: #fff;
  z-index: 999;

  img {
    width: 35px;
  }

  .heading {
    font-weight: 600 !important;
  }
}

.saveUserStaticQrModal {
  margin-top: -50px !important;
}
