$body-background-color: #f6f6f6;
$font-color-main: rgb(89, 35, 127);
$font-color-grey: #978f8f;
$font-color-white: #ffffff;
$background-color-white: #ffffff;
$background-color-main: rgb(89, 35, 127);
$background-color-main-grey: #919eab;
$background-color-grey-1: #919eab;
$background-color-grey-2: #efefef;
$card-background-color: #ffffff;
$button-background-color: rgb(114, 190, 68);
$button-font-color: rgb(114, 190, 68);
$card-shadow-color: 0px 16px 24px 0px rgba(0, 0, 0, 0.05);
$font-family-global: "Rubik", sans-serif;

.txt-right {
  text-align: right;
}

.output-wrapper {
  box-sizing: border-box;
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(230, 230, 230);
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 30px;
  font-size: 1.2em;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  border: 1px solid rgba(180, 180, 180, 0.5);
}

html,
body {
  height: 97%;
}

body {
  margin: 0;
  background: $body-background-color;
}

.backgroundBlack {
  background: #000000 !important;
  top: 0;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
}

.backgroundWhite {
  background: $background-color-white !important;
  top: 0;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
}

.main-font-color {
  color: $font-color-main !important;
}

.main-border-color {
  border: 1px solid $font-color-main !important;
}

.default-btn-style {
  background: $background-color-white !important;
  border: 1px solid $font-color-main !important;
  color: $font-color-main !important;
  border-radius: 7px !important;
}

.active-btn-style {
  background: $button-background-color !important;
  border: 1px solid $button-background-color !important;
  color: $font-color-white !important;
  border-radius: 7px !important;
}

.spin-style {
  color: $font-color-white;
}

.main-logo {
  width: 200px !important;
  margin-left: 0px !important;
  margin-top: 5px !important;
}

.ant-form-item-label {
  label {
    color: $font-color-main !important;
    height: 22px !important;
  }
}

.main-font-color-black {
  color: #000;
}

.wdz-card {
  box-shadow: $card-shadow-color;
  border-radius: 10px;
  border-top: 1px solid rgb(0 0 0 / 10%);
  background: $card-background-color;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.wdz-wallet-address-card {
  box-shadow: $card-shadow-color;
  border-radius: 10px;
  padding: 5%;
  border-top: 1px solid rgb(0 0 0 / 10%);
  background: $card-background-color;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.wdz-btn-primary {
  background: $background-color-main;
  border-radius: 7px;
  color: $font-color-white;
  border: 0;
  font-weight: 500;
  font-family: $font-family-global;
  font-style: normal;
}

.wdz-btn-primary:hover {
  background: $background-color-main;
}

.wdz-btn-grey {
  background: $background-color-grey-2;
  border-radius: 7px;
  color: #2e3033;
  border: 0;
  font-weight: 500;
  font-family: $font-family-global;
  font-style: normal;
}

.wdz-btn-grey:hover {
  background: $background-color-grey-2;
  color: #2e3033;
}

.wdz-btn-xl {
  width: 190px;
  height: 40px;
}

.wdz-btn-md {
  width: 140px;
  height: 35px;
}

.btn-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signIn {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: end;

  .loginBG {
    width: 100%;
    background-color: #f4ede5;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 1px);
    zoom: normal;
  }

  .userAuth {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .userAuthForm {
    padding: 4%;
    text-align: center;
    background: #fff9f2;
    backdrop-filter: blur(4px);
    border-radius: 15px;
  }

  .userCopyright {
    margin-top: 50px;
    text-align: center;
  }

  .userAuthWTKLogo {
    img {
      width: 40%;
      height: 50px;
    }
  }

  .userAuthLoginText {
    h5 {
      font-size: 1em;
      font-family: $font-family-global;
      font-style: normal;

      line-height: 40px;
      letter-spacing: 0.8px;
      color: #000000;
      margin: 0;
    }

    p {
      font-size: 0.8em;
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 500;
      line-height: 5px;
      letter-spacing: 0.2px;
    }
  }

  .userInput {
    background: $background-color-white;
    border: 1px solid #dedede;
    border-radius: 10px;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4375px;
    // color: rgba(39, 39, 39, 0.4);
    width: 100%;
    height: 38px;
    padding: 4%;
  }

  .signInBtn {
    background-color: $background-color-main;
    width: 100%;
    color: #3d3d3d;
    outline: 0;
    border: 0;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 4%;
  }

  .signInBtnGrey {
    background-color: $background-color-main-grey;
    width: 100%;
    color: #3d3d3d;
    outline: 0;
    border: 0;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 4%;
  }

  .userAuthTerms {
    P {
      margin-top: 50px;
      margin-bottom: 0;
    }

    p > a {
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #3d3d3d;
      text-decoration: none;
    }
  }

  .forgotPassword {
    text-decoration: none;
    cursor: pointer;
  }
}

.qrCodeContainer {
  background: white;
  text-align: center;
  border: 15px solid #ffc234;
  border-radius: 15px;
  width: 49%;
  margin: 0 auto;
}

.userCopyright {
  font-family: $font-family-global;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.userInput::placeholder {
  color: rgba(39, 39, 39, 0.4);
}

.userInput:focus {
  outline: none;
}

.signInBtn:hover {
  cursor: pointer;
}

.signInBtn:focus {
  outline: none;
  box-shadow: none;
}

.header {
  background-color: #000;
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  height: 80px;
  overflow: auto;
  width: 83.33333%;
  margin-left: 16.7%;
}

.header-wadzpay {
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  height: 80px;
  overflow: auto;
  width: 100%;
}

.header-wadzpay-row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.available-balance-block {
  width: 40%;
  font-family: $font-family-global;
  font-style: normal;
  border: 1px solid #c4c4c4;
  box-shadow: 0 0 2px rgb(0, 0, 0);
  left: 0;
}

.header-row {
  font-size: calc(10px + 1vmin);
  color: white;
  height: 50px;
}

.header-available-balance {
  margin-top: 60px;
  text-align: center;
  color: "#BEBEBE";
}

.logo-container {
  margin-top: 10px;
  // min-width: 200px;
}

.PhoneInputInput {
  border: 0;
}

.navigation-container {
  background: $background-color-white;
  font-family: $font-family-global;
  font-style: normal;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px rgb(0, 0, 0);
  clip-path: inset(0px -15px 0px 0px);
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
}

.main-container {
  margin-left: 16.1%;
  background: $body-background-color;
  padding: 2%;
  //height: 160vh;
  height: calc(100% - 5px);
}

.scan-pay-tab {
  height: 50px;
  width: 50px;
  background-color: $background-color-main;
  border-radius: 50%;
  display: inline-block;
}

.container-body {
  margin-top: 70px;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.transaction-count {
  background: #f6f7fa !important;

  td:nth-child(1) {
    text-align: left !important;
  }
}

.transaction-count td:nth-child(5),
.transaction-count td:nth-child(7) {
  text-align: center;
}

.search-crypto-input {
  height: 32px;
  border: 1px solid #dee2eb;
  border-radius: 7px;
  margin-left: 5px;
}

.search-crypto-input input {
  border: 0;
  width: 100%;
  padding-left: 3px;
  padding-top: 4px;
}

.search-crypto-input input:focus {
  outline: none;
}

/* enable absolute positioning */
.search-addon {
  position: relative;
  margin-left: 1px;
  display: flex;
  align-items: center;
}

/* style icon */
.search-addon .search-icon {
  position: absolute;
  margin: 7px;
  pointer-events: none;
}

/* align icon */
.right-addon .search-icon {
  right: 0px;
  margin-right: 6px;
}

/* add padding  */
.search-crypto-input svg {
  padding-right: 30px;
}

.custom-Datepicker-Periods {
  display: flex;
  margin: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    background: $background-color-grey-2 !important;
    color: black !important;
  }
}

.custom-Datepicker-Periods a {
  margin: 4px;
}

.transaction-filter-dropdown {
  border: 1px solid #dee2eb;
  border-radius: 7px;
}

.transaction-filter-dropdown:hover {
  background: $background-color-grey-2;
  color: black;
}

.sales-transactions-table {
  thead {
    background: #f6f7fa;
    border-radius: 5px;

    tr {
      th {
        color: #2e3033;
        font-family: $font-family-global;
        font-style: normal;
        font-weight: 600;
      }

      // td {
      //   text-align: center;
      // }
    }
  }

  tbody {
    border: 0 !important;

    tr {
      border-bottom: 1px solid #ececec !important;
    }
  }
}

.transactions-table {
  thead {
    background: #f6f7fa;
    border-radius: 5px;

    tr {
      th {
        color: #2e3033;
        font-family: $font-family-global;
        font-style: normal;
        font-weight: 600;
      }

      // td {
      //   text-align: center;
      // }
    }
  }

  tbody {
    border: 0 !important;

    tr {
      border-bottom: 1px solid #ececec !important;
    }

    td:nth-child(3),
    td:nth-child(4) {
      text-align: right;
    }
  }
}

.setings-tabs {
  // border-bottom: 0 !important;
  padding-bottom: 10px;

  li {
    margin: 0 20px 0 0;

    :hover {
      border-radius: 19px !important;
    }

    button {
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #979899;
    }
    // font-weight: 500;

    button[aria-selected="true"] {
      color: white !important;
      background: $background-color-main !important;
      border-radius: 19px !important;
    }
  }
}

.trxbalances {
  display: flex;
  align-items: center;
}

.trxbalancesData {
  // text-align: left;
  p {
    font-size: 16px;
    margin: 0;
  }
}

.fiatAssetFontSize {
  font-size: 0.9em;
}

.loginPassword {
  display: flex;
  align-items: center;
  background: $background-color-white;
  border: 1px solid #dedede;
  border-radius: 10px;
  font-family: $font-family-global;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4375px;
  // color: rgba(39, 39, 39, 0.4);
  width: 100%;
  height: 40px;

  input:nth-child(1) {
    border: 0 !important;
  }

  div {
    border: 0;
    cursor: pointer;
    padding-right: 2%;
  }
}

.signinForm-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  text-align: center;
  font-size: 12px;
}

.customFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.updatePasswordOTPForm {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .userAuth {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .userAuthForm {
    padding: 4%;
    text-align: center;
    background: $background-color-white;
    backdrop-filter: blur(4px);
    border-radius: 15px;
  }

  .userCopyright {
    margin-top: 50px;
    text-align: center;
  }

  .userAuthWTKLogo {
    img {
      width: 40%;
      height: 50px;
    }
  }

  .userAuthLoginText {
    h5 {
      font-size: 1em;
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: #000000;
      margin: 0;
    }

    p {
      font-size: 0.8em;
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 500;
      line-height: 5px;
      letter-spacing: 0.2px;
    }
  }

  .userInput {
    background: $background-color-white;
    border: 1px solid grey;
    border-radius: 10px;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4375px;
    // color: rgba(39, 39, 39, 0.4);
    width: 100%;
    height: 38px;
    padding: 4%;
  }

  .signInBtn {
    background-color: $background-color-main;
    width: 100%;
    color: #3d3d3d;
    outline: 0;
    border: 0;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 4%;
  }

  .signInBtnGrey {
    background-color: $background-color-main-grey;
    width: 100%;
    color: #3d3d3d;
    outline: 0;
    border: 0;
    font-family: $font-family-global;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    border-radius: 12px;
    padding: 4%;
  }

  .userAuthTerms {
    P {
      margin-top: 50px;
      margin-bottom: 0;
    }

    p > a {
      font-family: $font-family-global;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #3d3d3d;
      text-decoration: none;
    }
  }

  .forgotPassword {
    text-decoration: none;
    cursor: pointer;
  }
}

.refundPopup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: white;
  height: 800px;
  zoom: 90%;

  h3 {
    text-align: center;
    margin-top: 10px;
  }

  .wdz-card {
    padding-top: 5px;
    width: 100%;
  }
}

.refundbtn {
  background-color: lightgrey;
}

.refundbtnOpacity {
  opacity: 0.4 !important;
}

.mobileNumberInputs {
  display: flex;

  select {
    width: 20%;
    margin-right: 2%;
  }

  input {
    width: 78%;
  }
}

.signatureInput {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid grey !important;
  border-radius: 0 !important;
  outline: 0;
}

.dashBoardBg {
  height: "700px";
  background: "#f4ede5";
}

.refundBg {
  background: white;
}

.tooltip {
  position: relative;
}

.tooltip::before {
  background-color: rgb(12, 12, 12);
  border: 1px solid #888;
  border-radius: 2px;
  color: rgb(255, 253, 253);
  content: attr(data-title);
  display: none;
  font-family: sans-serif;
  font-size: 16px;
  padding: 2px 5px;
  position: absolute;
  top: 30px;
  right: 5px;
  z-index: 1;
}

.tooltip:hover::before {
  display: block;
}

@media (min-width: 240px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .signIn {
    .userAuth {
      width: 100%;
    }
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .signIn {
    .userAuth {
      width: 100%;
    }
  }
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .signIn {
    .userAuth {
      width: 50%;
    }
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .signIn {
    .userAuth {
      width: 50%;
    }
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .signIn {
    .userAuth {
      width: 50%;
    }
  }
}

.header-name {
  font-size: 20px;
}

.buttonToLink {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
}

.buttonToLink:hover {
  background: none;
  text-decoration: underline;
}

.textareaTnc {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid gray;
  height: 360px;
  overflow: auto;
  padding: 2px;
  width: 400px;
}

li {
  margin-bottom: 10px;
}

.atm {
  text-align: center;
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.inputbox-wrap {
  display: flex;

  button {
    padding-right: 10px;
    background-color: $background-color-white;
  }
}

.search-clear {
  position: absolute;
  font-weight: 600;
  border: none;
  background-color: transparent;
  line-height: 28px;
  left: calc(100% - 20px);
  z-index: 99999;
  font-size: 15px;
  color: #0d6efd;
}

.ant-carousel {
  .slick-dots {
    li button {
      background: #978f8f;
      opacity: 1;
      position: absolute;
      margin-top: 60px;
    }

    li.slick-active button {
      background: #000000;
      opacity: 1;
      position: absolute;
      margin-top: 60px;
    }
  }
}

// .active-tab {
//   filter: invert(75%) sepia(8%) saturate(468%) hue-rotate(227deg)
//     brightness(97%) contrast(91%);
// }

.active-tab {
  filter: invert(100%) sepia(8%) saturate(468%) hue-rotate(227deg)
    brightness(10%) contrast(100%);
}

.custom-carousel {
  padding: 0 0 0 0;
  height: 120px;
  color: $font-color-white;
  line-height: 20px;
  border-radius: 20px;
  background: $background-color-white;

  .carouselItem {
    padding-top: 15px;
    font-size: 15px;
    font-weight: 0;
    letter-spacing: 0.1px;
  }
}

// GeIdea start
.block-border {
  border: 1px solid #dedede;
  border-radius: 4px;
}

.border-button-refund {
  border: 1px solid #2e3033;
  border-radius: 2px;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.margin-block {
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 6px;
}

.text-button-style {
  font-size: 16px;
  // color: #6d6d6d;
  font-family: $font-family-global;
  font-style: normal;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-top: 8px;
}

.margin-botom-other-payment {
  margin-bottom: 10px;
}

.padding-botom-other-payment {
  padding-bottom: 18px;
}

.text-style-radio-header {
  font-size: 16px;
  // color: #6d6d6d;
  color: #363738;
  font-weight: 400;
}

.text-style-bank-detail {
  margin-left: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
  color: #363738;
  font-weight: 500;
}

.text-style-bank-detail-disabled {
  margin-left: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
  color: "#979899";
}

h5.welcomeHeading {
  font-size: 16px;
  text-align: center;
}

.geidea {
  margin-bottom: 45px;
  margin-top: auto;

  // overflow: scroll;
  // overflow-y: scroll;
  // overflow-x: hidden;
  .available-bal-box {
    .available-bal-inner {
      background: #ececec;
    }
  }

  .CarouselBox {
    .CarouselBox-inner {
      background: linear-gradient(rgb(89, 35, 127), rgb(89, 35, 127));
      color: $font-color-white !important;
    }
  }

  .shadow {
    box-shadow: 0 0 2px #e5e0e0 !important;
  }

  .shadow-box-1 {
    width: 95%;
    height: 100px;
    background-color: $background-color-white;
    margin-left: 10px;
    padding-top: 20px;
    text-align: center;
    font-weight: 0;

    code {
      font-size: 14px;
      color: #000;
      word-wrap: break-word;
    }
  }

  .shadow-box-2 {
    width: 95%;
    height: 100px;
    background-color: $background-color-white;
    margin-left: 0px;
    padding-top: 20px;
    text-align: center;
    margin-left: -5px;

    code {
      font-size: 14px;
      color: #000;
      word-wrap: break-word;
    }
  }

  .shadow-box-3 {
    width: 95%;
    height: 100px;
    background-color: $background-color-white;
    margin-left: 15px;
    text-align: left;

    code {
      font-size: 14px;
      color: #000;
      word-wrap: break-word;
    }
  }

  .shadow-box-4 {
    width: 95%;
    height: 100px;
    background-color: $background-color-white;
    margin-left: 0px;

    text-align: left;
    margin-left: -5px;

    code {
      font-size: 14px;
      color: #000;
      word-wrap: break-word;
    }
  }

  .shadow-box-5 {
    width: 95%;
    height: 70px;
    background-color: $background-color-white;
    margin-left: 0px;

    text-align: left;
    margin-left: -5px;

    code {
      font-size: 14px;
      color: #000;
      word-wrap: break-word;
    }
  }
}

.customTable {
  td {
    border: 0 !important;
  }

  .settingsSectionImage {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-left: -10px;
    padding: 9px 0px 0px 16px;
  }

  .settingsSectionText {
    position: absolute;
    width: 240px;
    height: 40px;
    margin-left: 50px;
    padding: 10px 0px 0px 0px;
  }

  .tItemAlign {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 8px;
  }

  .tItem1 {
    margin-left: -10px;
    color: #6d6d6d;
    margin-top: 8px;
    text-align: center;
    padding-top: 9px;
    font-size: 20px;
    position: absolute;
  }
  // font-weight: 600;

  .tItem2 {
    position: absolute;
    margin-left: 30px;
    font-size: 14px;
    color: #474646;
    margin-top: 5px;
  }
  // font-weight: 600;
  .tItem3 {
    position: absolute;
    margin-top: 25px;
    letter-spacing: 0px;
    font-size: 12px !important;
    margin-left: 30px;
    color: #474646;
  }

  .tItem7 {
    position: absolute;
    margin-top: 45px;
    letter-spacing: 0px;
    font-size: 13px;
    margin-left: 40px;
    color: #474646;
    font-size: 14px;
  }

  .tItem6 {
    border: 2px solid #e8e8e8;
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 53px;
  }

  .tItem4 {
    position: absolute;
    margin-top: 5px;
    font-size: 14px;
    right: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  // font-weight: 600;

  .tItem5 {
    position: absolute;
    margin-top: 45px;
    letter-spacing: 0px;
    font-size: 12px !important;
    margin-left: 30px;
    color: #474646;
  }
}

.ant-spin-dot-item {
  background-color: $background-color-white !important;
  opacity: 1 !important;
  transform: scale(0.85) !important;
}

.ant-drawer-content-wrapper {
  height: 600px !important;

  .ant-drawer-content {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
  }

  .ant-drawer-title {
    font-size: 20px !important;
  }

  .ant-space {
    display: flex !important;
    justify-content: center !important;
  }

  .logo-sart-balance {
    display: flex;
    align-items: baseline;
    justify-content: baseline;
    margin-top: 8px;
  }

  .sart-bal-size {
    font-size: 20px !important;
    font-weight: 600;
    color: #000000;
  }

  .filter {
    label {
      font-size: 18px !important;
      font-weight: 600;
    }

    button {
      color: #ababab;
      font-size: 15px;
    }

    .datepicker-custom {
      border-radius: 0% !important;
      border-top: none;
      border-left: none;
      border-right: none;
    }

    .ant-btn-default:not(:disabled):hover {
      color: #ffc235;
      border-color: #ffc235;
    }
  }

  .checkbox-button {
    button {
      width: 28% !important;
      margin-top: 1% !important;
      margin-right: 2% !important;
      margin-bottom: 2% !important;
    }
  }
  .checkbox-button-long {
    button {
      width: auto !important;
      margin-top: 1% !important;
      margin-right: 2% !important;
      margin-bottom: 2% !important;
      text-wrap: balance !important;
      height: auto !important;
      line-height: 18px !important;
    }
  }
  .success-button {
    button {
      width: 31% !important;
      margin-top: 1% !important;
      margin-right: 2% !important;
      margin-bottom: 2% !important;
    }
  }

  .applyButton {
    width: 250px;
    border-color: $button-background-color !important;
    color: #ffffff !important;
    border-radius: 3px !important;
    height: 60px !important;
    border-radius: 15px !important;
    background: $button-background-color;
    font-size: 20px !important;
    font-weight: 600;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-input:focus {
  outline: none !important;
}

// Input pincode styles
input.pincode-input-text {
  border-radius: 10px;
  border-color: #c4c4c4 !important;
  justify-content: center !important;
}

.passCodeButton {
  width: 80%;
  text-align: center;
  margin-top: 20px;
  margin-left: 10%;
  font-size: 16px;
  color: rgb(30, 30, 30);
  background-color: rgb(255, 194, 53);
  border-color: rgb(255, 194, 53);
  font-weight: 500;
}

.readonly-otp-container input {
  pointer-events: none;
}

.ant-notification-center {
  left: 50%;
  bottom: 50% !important;
  margin-right: 30%;
  transform: translate(-50%, -50%);
}

.ant-notification-bottom {
  bottom: 50px !important;
}

.skeletonImage {
  width: 235px !important;
  height: 235px !important;
}

.skeletonImageBig {
  width: 100% !important;
  height: 280px !important;
}

.selectedFilters {
  margin-top: 10px;
  margin-bottom: 10px;
}

.filterButton {
  padding-left: 5px;
  color: #ffc235;
  border-color: #fcf0da;
  margin: 5px 0px 5px 5px;
  font-size: 11px;
  background: #fcf0da;
}

// Transaction confirmation
.ant-card-bordered {
  border: 1px solid #c4c4c4;
}
.ant-card-head {
  font-weight: 400 !important;
  height: 90px !important;
  background: #f8f8f8 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  .ant-card-head-title {
    position: absolute !important;
    top: 20px;
    color: #717171 !important;
  }
  .ant-card-extra {
    position: absolute;
    top: 45px;
  }
}

.ant-card-meta-detail {
  display: flex !important;
  justify-content: space-between !important;

  .ant-card-meta-title {
    white-space: pre-line !important;
    color: #717171 !important;
  }
  .ant-card-meta-description {
    font-weight: 600 !important;
    color: $font-color-main !important;
  }
}
ul.ant-card-actions {
  padding: 10px 24px 0px 24px !important;
  border-top: 1px solid $background-color-main !important;
  background: $background-color-main !important;
  .ant-card-meta-title {
    color: $font-color-white !important;
  }
  .ant-card-meta-description {
    color: $font-color-white !important;
  }
}
.lowBalance {
  background: #986b1f;
  width: 70%;
  margin-left: 17% !important;
  font-size: 12px;
  color: $font-color-white;
  height: 28px;
  border-radius: 5px;
}
span.low {
  position: absolute;
  left: 90px;
  top: 96px;
}
.warningIcon {
  position: absolute;
  margin-top: 7px;
  left: 75px;
}
.whiteFullWidth {
  background: $background-color-white;
  width: 100%;
  height: 95%;
  position: absolute;
  left: 0;
}
.topup-input {
  input {
    height: 40px !important;
    margin-left: -10px !important;
    font-size: 18px !important;
  }
  // input[type="text"]:focus {
  //   border: 0px solid #d9d9d9;
  // }
  // input:hover {
  //   border: 0px solid #d9d9d9;
  // }
  .ant-input-group-addon {
    background-color: rgb(255 255 255 / 2%);
    font-size: 18px !important;
    height: 50px !important;
  }
  .ant-select-selector,
  .ant-select-selection-search {
    // width: 100% !important;
    height: 50px !important;
    font-size: 18px !important;
    padding: 2px 11px !important;
    width: 100% !important;
    min-width: 200px !important;
  }
  .ant-input {
    height: 50px !important;
    margin-left: 0px !important;
  }
}

.form-check-input:checked {
  background-color: $background-color-main;
  border-color: $background-color-main !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $background-color-main !important;
  background-color: $background-color-main !important;
}

.ant-popover-placement-top {
  width: 75% !important;
  left: 12.5% !important;
  .ant-btn-sm:last-child {
    background: $background-color-main !important;
  }
}

.redeemNutton {
  width: 100%;
}

.internalTransaction {
  .ant-input-group-addon {
    background: #ececec !important;
  }
}

.dot {
  height: 35px;
  width: 35px;
  background-color: #ececec;
  border-radius: 50%;
  display: inline-block;
}

.loader {
  /* Center the div horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Set background color and text styles */
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  color: white;
  font-size: 18px;
  padding: 20px;
  position: fixed; /* Fix the position */
  top: 0; /* Position from the top */
  left: 0; /* Position from the left */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  z-index: 9999; /* Ensure it's above other content */
}

.alert-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;

  .ant-btn-primary {
    color: $font-color-main !important;
    background-color: #fff !important;
    border: 1px solid $font-color-main !important;
  }
}

// .languages {
.ant-dropdown-menu-item {
  padding: 6px 12px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  color: #006cd7 !important;
  font-weight: 600;
  font-size: 13px;
  background-color: #f6f6f6;
  margin-top: 5px;
  border-radius: 0px;
}

.ant-dropdown .ant-dropdown-menu {
  width: 150px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e8e8e8 !important;
}
// }

/* Media query for mobile screens */
@media only screen and (max-width: 600px) {
  .loader {
    /* For mobile screens, adjust the styles to center the div */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .main-logo {
    width: 50% !important;
  }
}
