.custom-input {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  width: 20px !important;
  height: 20px;
  font-size: 20px;
  color: transparent;
  font-weight: 400;
  caret-color: #ffc235;
  margin-left: 8%;
  transition: background-color 0.3s ease-in-out;
}

.filled {
  background-color: #ffc235; /* Apply background color to non-empty inputs */
  border: #ffc235;
}
